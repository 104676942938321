const TableFund = ({ funds }) => {
    return (
        <div className="overflow-x-auto">
            <div className="bg-white shadow-md rounded my-6">
                <table className="min-w-max w-full table-auto">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">No.</th>
                            <th className="py-3 px-6 text-left">Nama</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {funds &&
                            funds.map((fund, i) => (
                                <tr
                                    className="border-b border-gray-200 hover:bg-gray-100 hover:cursor-pointer"
                                    key={i}
                                >
                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                        <div className="flex items-center">
                                            <span className="font-medium">
                                                {i + 1}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="py-3 px-6 text-left">
                                        <span>{fund.name}</span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableFund;
