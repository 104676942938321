import axios from "axios";
import Auth from "../modules/Auth/auth";
import TokenStorage from "./token-storage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

instance.interceptors.request.use((config) => {
  if (TokenStorage.hasToken()) {
    if (TokenStorage.getTokenExp() < Date.now() / 1000) {
      Auth.refreshToken()
        .then(() => {
          let token = TokenStorage.getToken();
          config.headers["Authorization"] = "Bearer " + token;
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      const token = TokenStorage.getToken();
      if (token !== "") {
        config.headers["Authorization"] = "Bearer " + token;
      } else {
        console.error("kosong");
      }
    }
  }
  return config;
});

export default instance;
