import { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";

const Validation = ({
  setModal,
  role,
  passwordChanged,
  setPasswordChanged,
}) => {
  const [spmList, setSpmList] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [validateType, setValidateType] = useState(true);
  const [unit, setUnit] = useState("");

  const renderStatus = (verify) => {
    switch (verify) {
      case 0:
        return <span className="mr-12">Menunggu Validasi</span>;

      case 2:
        return <span className="mr-12 text-red-600">SPM Ditolak</span>;

      default:
        break;
    }
  };

  const ValidateSPM = (unit, validate) => {
    setModal("Loader");
    axios
      .post(`/spm/${unit}/verify`, { verify: validate ? 1 : 2 })
      .then((resp) => setModal("Success"))
      .catch((err) => {
        console.error(err);
        setModal(err);
      });
  };

  useEffect(() => {
    axios
      .get("/spm/upload/list")
      .then((resp) => setSpmList(resp.data.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <div>
        {role === "validator" && !passwordChanged && (
          <div className="flex justify-between items-center text-white px-6 py-4 border-0 rounded relative mb-4 bg-yellow-400">
            <span>Password belum diubah! Mohon segera ubah password anda.</span>
            <span
              className="font-bold cursor-pointer p-1"
              onClick={() => setPasswordChanged(1)}
            >
              X
            </span>
          </div>
        )}
        <h1 className="text-2xl border-b-2 mb-2">Validasi</h1>
        {spmList ? (
          spmList?.map((spm) => (
            <div
              key={spm.unit}
              className="flex justify-between items-center px-4 py-2 bg-white shadow-md mb-4 rounded-md"
            >
              <div>
                <span className="mr-12">{spm.unit}</span>
                <a
                  className="text-blue-600 mr-12 cursor-pointer"
                  href={`${process.env.REACT_APP_API_ENDPOINT}/spm/${spm.unit}/download`}
                >
                  Download
                </a>
                {renderStatus(spm.verify)}
              </div>
              <div>
                {spm.verify !== 2 && (
                  <button
                    className="bg-red-600 py-2 px-20 rounded-md text-right text-white"
                    onClick={() => {
                      setIsConfirm(true);
                      setValidateType(false);
                      setUnit(spm.unit);
                    }}
                  >
                    Tolak
                  </button>
                )}

                <button
                  className="bg-indigo-600 ml-4 py-2 px-20 rounded-md text-right text-white"
                  onClick={() => {
                    setIsConfirm(true);
                    setValidateType(true);
                    setUnit(spm.unit);
                  }}
                >
                  Validasi
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center text-white px-6 py-4 border-0 rounded relative mb-4 bg-yellow-400">
            <span className="inline-block text-center align-middle">
              Belum ada SPM yang diupload oleh unit
            </span>
          </div>
        )}
      </div>
      {/* modal */}
      <div
        className={`${
          isConfirm ? "flex" : "hidden"
        } absolute top-0 z-50 left-0 right-0 overflow-hidden w-full h-full justify-center items-center bg-gray-600 bg-opacity-50`}
        style={{ right: "3%" }}
      >
        <div className="flex flex-col items-center bg-white p-4 w-96 rounded-xl shadow-2xl">
          <CloseIcon
            className="self-end mb-2 w-8 h-8 cursor-pointer"
            onClick={() => {
              setIsConfirm(false);
            }}
          />
          <h4 className="text-2xl mb-4">
            {validateType ? "Validasi SPM ?" : "Tolak SPM ?"}
          </h4>
          <div className="pb-8 mt-4">
            <button
              className="bg-red-500 py-2 px-6 mr-4 rounded-md text-right text-white hover:bg-red-400"
              onClick={() => {
                setIsConfirm(false);
              }}
            >
              Batal
            </button>
            <button
              className="bg-indigo-600 py-2 px-6 rounded-md text-right text-white hover:bg-indigo-500"
              onClick={() => ValidateSPM(unit, validateType)}
            >
              {validateType ? "Validasi" : "Tolak"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Validation;
