import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import axios from "../../../lib/axios";
import { userRoles } from "../../Auth/dashboardAccess";

const UserForm = ({ onSubmit, pageRef, selectedUser }) => {
  const name = useRef(null);
  const email = useRef(null);
  const username = useRef(null);
  const roleRef = useRef(null);
  const unitRef = useRef(null);
  const { register, handleSubmit } = useForm();
  const [units, setUnits] = useState([]);
  const [role, setRole] = useState("");

  useEffect(() => {
    if (selectedUser.id) {
      pageRef.current.scrollIntoView();
      name.current.value = selectedUser.fullname;
      email.current.value = selectedUser.email;
      username.current.value = selectedUser.username;
      roleRef.current.value = selectedUser.role;
      setRole(selectedUser.role);
      unitRef.current.value = selectedUser.unit_id;
    }
    document.querySelector("body").scrollTo(0, 0);
  }, [selectedUser]); // eslint-disable-line

  useEffect(() => {
    axios
      .get("/units")
      .then((res) => {
        setUnits(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4">
          <label>Name</label>
          <input
            {...register("fullname")}
            ref={name}
            type="text"
            required
            name="fullname"
            className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="my-4">
          <label>Email</label>
          <input
            {...register("email")}
            ref={email}
            type="text"
            name="email"
            required
            className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="my-4">
          <label>Username</label>
          <input
            {...register("username")}
            ref={username}
            type="text"
            name="username"
            required
            className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="my-4">
          <label>Password</label>
          <input
            {...register("password")}
            type="text"
            name="password"
            required
            className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="my-4">
          <label>Role</label>
          <select
            {...register("role")}
            ref={roleRef}
            className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
            onChange={(event) => setRole(event.target.value)}
          >
            <option className="text-sm">-- Role --</option>
            {userRoles.map((role) => (
              <option key={role} value={role} className="text-sm">
                {role}
              </option>
            ))}
          </select>
        </div>

        <div
          className="my-4"
          style={role !== "unit" ? { display: "none" } : {}}
        >
          <label>Unit</label>
          <select
            className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
            {...register("unit_id")}
            ref={unitRef}
          >
            <option className="text-sm">-- Unit --</option>
            {units.map((unit) => (
              <option key={unit.id} className="text-sm" value={unit.id}>
                {unit.name}
              </option>
            ))}
          </select>
        </div>

        <div className="my-4">
          <button className="bg-indigo-600 py-2 px-20 rounded-md text-right text-white">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
