import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const acceptedFileExtension = [
  "xls",
  "xlsb",
  "xlsx",
  "xlsm",
  "xlt",
  "xml",
  "xla",
  "xlr",
];

const FileDropzone = ({ setFile }) => {
  const [errorFileExt, setErrorFileExt] = useState(false);
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback(
    (acceptedFile) => {
      const fileExtension = acceptedFile[0]?.name.split(".").pop();
      if (acceptedFileExtension.includes(fileExtension)) {
        setFile(acceptedFile[0]);
        setFileName(acceptedFile[0].name);
        setErrorFileExt(false);
      } else {
        setFileName("");
        setErrorFileExt(true);
      }
    },
    [setFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: ".xls, .xlsb, .xlsx, .xlsm, .xlt, .xml, .xla, .xlr",
  });

  const dropzoneStyle =
    "cursor-pointer bg-white h-64 flex flex-col justify-center items-center border-4 border-dashed";

  return (
    <div {...getRootProps({ className: dropzoneStyle })}>
      <input {...getInputProps()} />
      <p>
        Tarik dan lepaskan file di sini atau klik untuk upload{" "}
        <span className="font-bold">(Format .xlsx)</span>
      </p>
      {errorFileExt && (
        <p className="text-red-600">{`Format file tidak didukung`}</p>
      )}
      <p className="text-indigo-600">{fileName}</p>
    </div>
  );
};

export default FileDropzone;
