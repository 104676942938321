import { ReactComponent as CloseIcon } from "../../../../images/close.svg";

import Loader from "./Loader/Loader";
import Success from "./Success/Success";
import SPMDetail from "./SPMDetail/SPMDetail";
import ConfirmDeleteSPM from "./Confirm/ConfirmDeleteSPM";
import Untad from "./Untad/Untad";

const MODAL_LIST = {
  Loader,
  Success,
  SPMDetail,
  ConfirmDeleteSPM,
  Untad,
};

const Modal = ({ modal, setModal, modalData }) => {
  const Content = MODAL_LIST[modal];

  return (
    <div
      className={`${
        modal ? "flex" : "hidden"
      } absolute top-0 z-50 w-full h-full bg-gray-600 bg-opacity-50 justify-center items-center`}
    >
      <div
        className={`flex flex-col items-center ${
          modal === "Loader" || modal === "Untad" ? "" : "bg-white px-3 py-3"
        }  w-96 rounded-xl`}
      >
        <CloseIcon
          className={`self-end mb-2 w-8 h-8 cursor-pointer ${
            modal === "Loader" || modal === "Untad" ? "hidden" : ""
          }`}
          onClick={() => {
            setModal(false);
            if (modal === "Success") window.location.reload();
          }}
        />

        {Content ? <Content data={modalData} setModal={setModal} /> : null}
      </div>
    </div>
  );
};

export default Modal;
