import { useState } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import LoginPage from "./modules/Auth/Login/LoginPage";
import Dashboard from "./modules/Dashboard/pages/Dashboard";
import Modal from "./modules/Dashboard/partials/Modal/";

function App() {
  const [modalData, setModalData] = useState({});
  const [modal, setModal] = useState(false);

  return (
    <Router>
      <Switch>
        <PublicRoute exact restricted={false} path="/">
          <Redirect to={{ pathname: "/login" }} />
        </PublicRoute>
        <PublicRoute
          exact
          restricted={true}
          path="/login"
          component={LoginPage}
          setModal={setModal}
        />

        <PrivateRoute
          path="/dashboard"
          component={Dashboard}
          setModalData={setModalData}
          setModal={setModal}
        />
      </Switch>
      <Modal modal={modal} setModal={setModal} modalData={modalData} />
    </Router>
  );
}

export default App;
