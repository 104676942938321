import { useCallback, useEffect, useRef, useState } from "react";

import axios from "../../../lib/axios";
import { usersAccess } from "../../Auth/dashboardAccess";
import UserForm from "../components/UserForm";
import TableUsers from "../components/TableUsers";
import LoadingScreen from "../components/LoadingScreen";
import { SortString } from "../utils/Sort";

const Users = ({ role, setModal }) => {
  const pageRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const onSubmitUser = useCallback(
    (data) => {
      setModal("Loader");
      if (data.role !== "unit") data.unit_id = 0;
      else data.unit_id = Number(data.unit_id);

      if (!data.fullname) data.fullname = selectedUser.fullname;
      if (!data.email) data.email = selectedUser.email;
      if (!data.username) data.username = selectedUser.username;
      if (!data.password) data.password = selectedUser.password;
      if (!data.role) {
        data.role = selectedUser.role;
        if (data.role !== "unit") data.unit_id = 0;
      }
      if (data.role !== "unit") data.unit_id = 0;

      selectedUser.id
        ? axios
            .put(`/users/${selectedUser.id}`, data)
            .then((res) => {
              if (res.data.code >= 200 && res.data.code < 300) {
                setModal("Success");
              } else {
                setModal(false);
                console.log("fail to add user");
              }
            })
            .catch((err) => {
              console.error(err);
              setModal(false);
            })
        : axios
            .post("/users", data)
            .then((res) => {
              if (res.data.code >= 200 && res.data.code < 300) {
                setModal("Success");
              } else {
                setModal(false);
                console.log("fail to add user");
              }
            })
            .catch((err) => {
              console.error(err);
              setModal(false);
            });
    },
    [selectedUser.id] // eslint-disable-line
  );

  useEffect(() => {
    if (usersAccess.includes(role)) {
      setIsLoading(true);
      axios
        .get("/users")
        .then((res) => {
          setUsers({
            type: "username-asc",
            data: SortString(res.data.data, "username", "asc"),
          });
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [role]); // eslint-disable-line

  return (
    <>
      {usersAccess.includes(role) && (
        <div ref={pageRef}>
          <h1 className="text-2xl border-b-2">Users</h1>
          {/* <hr /> */}
          <UserForm
            onSubmit={onSubmitUser}
            pageRef={pageRef}
            selectedUser={selectedUser}
          />
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <TableUsers
              users={users}
              setUsers={setUsers}
              setModal={setModal}
              setSelectedUser={setSelectedUser}
              pageRef={pageRef}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Users;
