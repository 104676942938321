import { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import TableFund from "../components/TableFund";
import LoadingScreen from "../components/LoadingScreen";

const Funds = () => {
    const [funds, setFunds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get("/funds")
            .then((res) => {
                setFunds(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div>
            <h1 className="text-2xl border-b-2">Sumber Dana</h1>
            {isLoading ? <LoadingScreen /> : <TableFund funds={funds} />}
        </div>
    );
};

export default Funds;
