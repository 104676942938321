const Untad = () => {
  return (
    <div>
      <img
        className="mx-auto h-64 w-auto animate-pulse"
        src="https://upload.wikimedia.org/wikipedia/commons/6/60/Logo_Universitas_Tadulako_Palu.png"
        alt="Workflow"
        style={{ filter: "drop-shadow(0 0.5rem 0.5rem black)" }}
      />
    </div>
  );
};

export default Untad;
