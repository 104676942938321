import { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorShow from "../../Dashboard/components/ErrorShow";
import auth from "../auth";

const LoginPage = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isError, setIsError] = useState(false);
  const [serverError, setServerError] = useState(false);

  const OnSubmitLogin = (data) => {
    props.setModal("Loader");
    auth
      .login(data)
      .then(() => {
        setServerError(false);
        if (auth.isAuthenticated()) {
          window.sessionStorage.setItem("simakota", true);
          props.history.push("/dashboard");
          setIsError(false);
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        props.history.push("/dashboard");
        setServerError(true);
      })
      .finally(() => props.setModal(false));
  };

  return (
    <div
      className="min-h-screen"
      style={{ background: "url(/untad.jpg) center/cover no-repeat" }}
    >
      <div
        className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
        style={{
          background:
            "linear-gradient(90deg, rgba(22,21,27,1) 50%, rgba(21,21,27,0.8) 100%",
        }}
      >
        <img
          className="absolute w-48 top-6 left-6 items-center flex"
          src="/logo-simakota.png"
          alt=""
        />
        <div className="w-full flex justify-center lg:justify-between items-center lg:px-32">
          <div>
            <div>
              <img
                className="mx-auto h-40 w-auto lg:absolute lg:hidden"
                src="https://upload.wikimedia.org/wikipedia/commons/6/60/Logo_Universitas_Tadulako_Palu.png"
                alt="Workflow"
              />
              <h2 className="mt-6 text-center lg:text-left text-5xl tracking-widest font-extrabold text-gray-300">
                SIMAKOTA
              </h2>
              <h2 className="text-center lg:text-left text-2xl font-extrabold text-gray-300">
                Universitas Tadulako
              </h2>
            </div>
            <form
              className="mt-8 space-y-6"
              onSubmit={handleSubmit(OnSubmitLogin)}
            >
              {!isError ? (
                <p className="text-center lg:text-left text-gray-400">
                  Silahkan Masukan Username dan Password Anda
                </p>
              ) : serverError ? (
                <ErrorShow msg="Terjadi kesalahan pada server" />
              ) : (
                <ErrorShow msg="Gagal login! Mohon periksa kembail Username dan Password anda" />
              )}
              <input type="hidden" name="remember" value="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="username" className="sr-only">
                    Username
                  </label>
                  <input
                    {...register("username", { required: true })}
                    id="username"
                    name="username"
                    aria-invalid={errors.username ? "true" : "false"}
                    type="text"
                    autoComplete="username"
                    // required
                    className="appearance-none rounded-2xl mb-4 relative block w-full px-8 py-4 border placeholder-gray-500 text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Username..."
                  />
                  {errors.username && (
                    <ErrorShow msg="field username is required" />
                    // <span role="alert"></span>?
                  )}
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    {...register("password", { required: true })}
                    id="password"
                    name="password"
                    aria-invalid={errors.password ? "true" : "false"}
                    type="password"
                    autoComplete="current-password"
                    // required
                    className="appearance-none rounded-2xl mb-4 relative block w-full px-8 py-4 border placeholder-gray-500 text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                  />
                  {errors.password && (
                    <ErrorShow msg="field password is required" />
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group rounded-2xl relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  Sign in
                </button>
              </div>
            </form>
          </div>
          <img
            className="h-72 w-auto hidden lg:inline-block"
            src="https://upload.wikimedia.org/wikipedia/commons/6/60/Logo_Universitas_Tadulako_Palu.png"
            alt="Workflow"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
