import { useEffect, useState } from "react";

import axios from "../../../lib/axios";
import ErrorShow from "../components/ErrorShow";
import LoadingScreen from "../components/LoadingScreen";
import Popup from "../components/Popup";

const Settings = ({ setModal }) => {
  const [accountDetails, setAccountDetails] = useState({
    id: 0,
    fullname: "",
    email: "",
    username: "",
    role: "",
    unit: 0,
  });

  // change password
  const [currentPasswrod, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // Popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupText, setPopupText] = useState("");
  const togglePopup = (title, text) => {
    setPopupTitle(title);
    setPopupText(text);
    setIsPopupOpen(!isPopupOpen);
  };
  const onClosePopup = () => setIsPopupOpen(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      current_password: currentPasswrod,
      new_password: newPassword,
      user_id: accountDetails.id,
    };

    if (newPassword === confirmPassword) {
      setModal("Loader");
      axios
        .put("/changepassword", payload)
        .then((res) => {
          if (res.data.code >= 200 && res.data.code < 300) {
            setModal("Success");
          } else {
            console.error("gagal mengubah password");
            setModal(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setModal(false);
        });
    } else {
      setModal(false);
    }
  };

  const isValidInput = () => {
    return (
      currentPasswrod !== "" &&
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword === confirmPassword
    );
  };

  useEffect(() => {
    axios
      .get("/details")
      .then((res) => {
        setAccountDetails(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div>
      {!isLoading ? (
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-2">
            {/* Name */}
            <div className="my-4 w-full">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={accountDetails.fullname}
                readOnly
                className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>

            {/* email */}
            <div className="my-4 w-full">
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={accountDetails.email}
                readOnly
                className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>

            {/* username */}
            <div className="">
              <label>Username</label>
              <input
                type="text"
                name="username"
                value={accountDetails.username}
                readOnly
                className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>

            {/* role */}
            <div className="">
              <label>Role</label>
              <input
                type="text"
                name="role"
                value={accountDetails.role}
                readOnly
                className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
          </div>

          <div className="mt-10">
            {/* unit */}
            {accountDetails.unit_id ? (
              <div className="my-4">
                <label>Unit</label>
                <input
                  type="text"
                  name="unit"
                  value={accountDetails.unit_id}
                  readOnly
                  className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>
            ) : null}
            <div>Ubah Password</div>
            {/* <label className="text-indigo-600">--Ubah Password--</label> */}

            {/* current password */}
            <div className="my-4">
              <label>Password</label>
              <input
                value={currentPasswrod}
                onChange={(event) => setCurrentPassword(event.target.value)}
                type="password"
                required
                name="current_password"
                className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>

            {/* new password */}
            <div className="my-4">
              <label>Password Baru</label>
              <input
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                type="password"
                required
                name="new_password"
                className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>

            {/* confirm password */}
            <div className="my-4">
              <label>Konfirmasi Password</label>
              <input
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                type="password"
                required
                name="new_password"
                className="w-full border-2 p-2 rounded-md focus:outline-none focus:border-indigo-500"
              />
              {newPassword !== confirmPassword && (
                <ErrorShow msg="Password tidak sesuai" />
              )}
            </div>

            <button
              disabled={!isValidInput()}
              className="bg-indigo-600 py-2 px-20 rounded-md text-right text-white disabled:opacity-50"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      ) : (
        <LoadingScreen />
      )}

      {/* Popup */}
      <Popup
        isOpen={isPopupOpen}
        title={popupTitle}
        text={popupText}
        actionBtn="Ok"
        toggle={togglePopup}
        onClose={onClosePopup}
      />
    </div>
  );
};

export default Settings;
