import { useRef } from "react";
import { FormatDate } from "../utils/Filter";

const FilterSPM = ({ setSpmDate, setTransactionDate, fetchSPM }) => {
  const spm = useRef(null);
  const transaction = useRef(null);

  const resetFilter = () => {
    setSpmDate("");
    setTransactionDate("");
    spm.current.value = "";
    transaction.current.value = "";
    fetchSPM(true);
  };

  return (
    <div className="flex justify-between items-center w-full mb-4">
      <div className="flex">
        <div className="mr-4">
          <span className="text-gray-700 mr-2">Tgl. SPM :</span>
          <input
            className="p-1 rounded-md"
            type="date"
            onChange={(e) => setSpmDate(FormatDate(e.target.value))}
            ref={spm}
          />
        </div>
        <div className="mr-4">
          <span className="text-gray-700 mr-2">Tgl. Transfer :</span>
          <input
            className="p-1 rounded-md"
            type="date"
            onChange={(e) => setTransactionDate(FormatDate(e.target.value))}
            ref={transaction}
          />
        </div>
      </div>
      <div>
        <button
          className="bg-red-600 py-2 px-6 mr-4 rounded-md text-right text-white hover:bg-red-500"
          onClick={resetFilter}
        >
          Reset
        </button>
        <button
          className="bg-indigo-600 py-2 px-6 rounded-md text-right text-white hover:bg-indigo-500"
          onClick={() => fetchSPM()}
        >
          Terapkan
        </button>
      </div>
    </div>
  );
};

export default FilterSPM;
