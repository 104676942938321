import { formatRupiah } from "../utils/Rupiah";
import { dashboardAccess } from "../../Auth/dashboardAccess";
import { useHistory } from "react-router";
import { ReactComponent as Dropdown } from "../../../images/dropdown.svg";
import LoadingScreen from "./LoadingScreen";
import { SortNumber, SortString } from "../utils/Sort";

export default function DashboardContent({
  summary,
  role,
  spmList,
  setSpmList,
  passwordChanged,
  setPasswordChanged,
  isLoading,
}) {
  const history = useHistory();

  const redirectUser = (role) => {
    switch (role) {
      case "unit":
        history.push("/dashboard/spm");
        break;

      case "validator":
        history.push("/dashboard/validation");
        break;

      default:
        break;
    }
  };

  return (
    <div className="dashboard">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {dashboardAccess.includes(role) && !passwordChanged && (
            <div className="flex justify-between items-center text-white px-6 py-4 border-0 rounded relative mb-4 bg-yellow-400">
              <span>
                Password belum diubah! Mohon segera ubah password anda.
              </span>
              <span
                className="font-bold cursor-pointer p-1"
                onClick={() => setPasswordChanged(1)}
              >
                X
              </span>
            </div>
          )}
          <h1 className="text-2xl border-b-2 mb-8">Dashboard</h1>
          {dashboardAccess.includes(role) ? (
            <>
              <div className="flex flex-col md:flex-row md:justify-around mb-5">
                <div
                  className="px-4 py-8 text-center font-medium shadow-xl flex-1 md:mr-5 md:mb-0 mb-4 bg-white rounded-md"
                  style={{
                    backgroundImage:
                      " linear-gradient(45deg, #d7ddff, transparent)",
                  }}
                >
                  <h2 className="mb-2">Total Pagu</h2>
                  <h4>{formatRupiah(summary?.total_pagu || 0)}</h4>
                </div>
                <div
                  className="px-4 py-8 text-center font-medium shadow-xl flex-1 md:mr-5 md:mb-0 mb-4 bg-white rounded-md"
                  style={{
                    backgroundImage:
                      " linear-gradient(45deg, #e7fce8, transparent)",
                  }}
                >
                  <h2 className="mb-2">Total Realisasi</h2>
                  <h4>{formatRupiah(summary?.total_realization || 0)}</h4>
                </div>
                <div
                  className="px-4 py-8 text-center font-medium shadow-xl flex-1 md:mr-5 md:mb-0 mb-4 bg-white rounded-md"
                  style={{
                    backgroundImage:
                      " linear-gradient(45deg, #e0ecff, transparent)",
                  }}
                >
                  <h2 className="mb-2">Persentase Serapan</h2>
                  <h4>{summary?.percentage?.toFixed(2) || 0}%</h4>
                </div>
                <div
                  className="px-4 py-8 text-center font-medium shadow-xl flex-1 bg-white rounded-md "
                  style={{
                    backgroundImage:
                      " linear-gradient(45deg, #f6eed4, transparent)",
                  }}
                >
                  <h2 className="mb-2">Total Unit</h2>
                  <h4>{summary?.spms?.length || 0}</h4>
                </div>
              </div>
              <div className="mt-2">
                <h2 className="border-b-2 mt-6 mb-2">Unit Fakultas</h2>
                <div className="overflow-x-scroll">
                  <table className="min-w-max w-full table-auto">
                    <thead>
                      <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal cursor-pointer">
                        <th className="py-3 px-6 text-left hover:bg-indigo-300 ">
                          No.
                        </th>
                        <th
                          className="py-3 px-6 text-left hover:bg-indigo-300 "
                          onClick={() =>
                            setSpmList({
                              type:
                                spmList.type === "unit-desc"
                                  ? "unit-asc"
                                  : "unit-desc",
                              spms:
                                spmList.type === "unit-desc"
                                  ? SortString(spmList.spms, "unit", "asc")
                                  : SortString(spmList.spms, "unit", "desc"),
                            })
                          }
                        >
                          <div className="flex justify-center items-center">
                            Unit Fakultas
                            <Dropdown
                              className="w-4 ml-2 transition"
                              style={
                                spmList.type === "unit-desc"
                                  ? {}
                                  : { transform: "rotate(-180deg)" }
                              }
                            />
                          </div>
                        </th>
                        <th
                          className="py-3 px-6 text-center hover:bg-indigo-300 "
                          onClick={() =>
                            setSpmList({
                              type:
                                spmList.type === "pagu-desc"
                                  ? "pagu-asc"
                                  : "pagu-desc",
                              spms:
                                spmList.type === "pagu-desc"
                                  ? SortNumber(
                                      spmList.spms,
                                      "total_pagu",
                                      "asc"
                                    )
                                  : SortNumber(
                                      spmList.spms,
                                      "total_pagu",
                                      "desc"
                                    ),
                            })
                          }
                        >
                          <div className="flex justify-center items-center">
                            Total Pagu
                            <Dropdown
                              className="w-4 ml-2 transition"
                              style={
                                spmList.type === "pagu-desc"
                                  ? {}
                                  : { transform: "rotate(-180deg)" }
                              }
                            />
                          </div>
                        </th>
                        <th
                          className="py-3 px-6 text-center  hover:bg-indigo-300 "
                          onClick={() =>
                            setSpmList({
                              type:
                                spmList.type === "realisasi-desc"
                                  ? "realisasi-asc"
                                  : "realisasi-desc",
                              spms:
                                spmList.type === "realisasi-desc"
                                  ? SortNumber(
                                      spmList.spms,
                                      "total_realization",
                                      "asc"
                                    )
                                  : SortNumber(
                                      spmList.spms,
                                      "total_realization",
                                      "desc"
                                    ),
                            })
                          }
                        >
                          <div className="flex justify-center items-center">
                            Total Realisasi
                            <Dropdown
                              className="w-4 ml-2 transition"
                              style={
                                spmList.type === "realisasi-desc"
                                  ? {}
                                  : { transform: "rotate(-180deg)" }
                              }
                            />
                          </div>
                        </th>
                        <th
                          className="py-3 px-6 text-center hover:bg-indigo-300 "
                          onClick={() =>
                            setSpmList({
                              type:
                                spmList.type === "serapan-desc"
                                  ? "serapan-asc"
                                  : "serapan-desc",
                              spms:
                                spmList.type === "serapan-desc"
                                  ? SortNumber(
                                      spmList.spms,
                                      "percentage",
                                      "asc"
                                    )
                                  : SortNumber(
                                      spmList.spms,
                                      "percentage",
                                      "desc"
                                    ),
                            })
                          }
                        >
                          <div className="flex justify-center items-center">
                            Persentase Serapan
                            <Dropdown
                              className="w-4 ml-2 transition"
                              style={
                                spmList.type === "serapan-desc"
                                  ? {}
                                  : { transform: "rotate(-180deg)" }
                              }
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-md">
                      {spmList.spms?.map((sum, idx) => (
                        <tr
                          className="border-b border-gray-200 bg-white hover:bg-gray-300 cursor-pointer"
                          key={idx}
                          onClick={() =>
                            history.push(`/dashboard/unit/${sum.unit}`)
                          }
                        >
                          <td className="py-3 px-6 text-left whitespace-nowrap">
                            <div className="flex items-center">
                              <span className="font-medium">{idx + 1}</span>
                            </div>
                          </td>
                          <td className="py-3 px-6 text-left">
                            <span>{sum.unit}</span>
                          </td>
                          <td className="py-3 px-6 text-center">
                            <span>{formatRupiah(sum.total_pagu)}</span>
                          </td>
                          <td className="py-3 px-6 text-center">
                            <span>{formatRupiah(sum.total_realization)}</span>
                          </td>
                          <td className="py-3 px-6 text-center">
                            <span>{sum.percentage?.toFixed(2) || 0}%</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            redirectUser(role)
          )}
        </>
      )}
    </div>
  );
}
