import axios from "axios";
import TokenStorage from "../../lib/token-storage";
class Auth {
  static login(req) {
    return axios
      .post("https://api.simakota.siuntad.online/v1/login", req)
      .then((res) => {
        TokenStorage.store(JSON.stringify(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  static refreshToken() {
    let tokenRef = TokenStorage.getRefresh();
    return axios
      .get("https://api.simakota.siuntad.online/v1/refresh", {
        headers: {
          Authorization: `Bearer ${tokenRef}`,
        },
      })
      .then((res) => {
        TokenStorage.store(JSON.stringify(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static logout(cb) {
    TokenStorage.revoke();
    cb();
  }

  static isAuthenticated() {
    return TokenStorage.hasToken();
  }
}

export default Auth;
