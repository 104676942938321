import { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "../../../lib/axios";

import LoadingScreen from "../components/LoadingScreen";
import FilterSPM from "../partials/FilterSPM";
import ErrorShow from "../components/ErrorShow";

export default function UnitDashboard({ setModal, setModalData }) {
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");
  const { unit } = useParams();
  const [spm, setSpm] = useState([]);
  const [meta, setMeta] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  // filter
  const [spmDate, setSpmDate] = useState("");
  const [transactionDate, setTransactionDate] = useState("");

  const fetchSPM = (isDefault) => {
    setIsLoading(true);
    const url = isDefault
      ? `/spm?page=${page || 1}&size=10&fakultas=${unit}`
      : `/spm?page=${
          page || 1
        }&size=10&fakultas=${unit}&spm_date=${spmDate}&transaction_date=${transactionDate}`;

    axios
      .get(url)
      .then((res) => {
        setMeta(res.data.meta);
        setSpm(res.data.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    fetchSPM();
  }, [page, unit]); // eslint-disable-line

  const openSPM = (data) => {
    setModalData(data);
    setModal("SPMDetail");
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          <FilterSPM
            setSpmDate={setSpmDate}
            setTransactionDate={setTransactionDate}
            fetchSPM={fetchSPM}
          />
          {spm?.map((data) => (
            <div
              key={data.id}
              className="flex flex-col py-2 px-4 shadow-md bg-white mb-4 rounded-xl"
            >
              <div className="flex justify-between mb-2">
                <div className="flex items-center">
                  <span className="mr-4 text-sm">
                    Tgl. SPM : {data.tanggal_spm || "-"}
                  </span>
                  <span className="mr-4 text-sm">
                    Tgl. Transfer Bank : {data.tanggal_transfer_bank || "-"}
                  </span>
                  <div className="text-sm grid place-items-center py-1 px-2 text-white bg-indigo-600 rounded-3xl w-24">
                    {data.output || "-"}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-lg mb-2">Uraian :</h6>
                <hr />
                <p className=" text-md mt-2">{data.uraian || "-"}</p>
              </div>
              <span
                className="text-indigo-400 mt-2 text-sm cursor-pointer mb-2"
                onClick={() => openSPM(data)}
              >
                Lihat Detail
              </span>
            </div>
          ))}

          <div className="flex justify-center items-center space-x-1">
            {spm?.length ? (
              <>
                <Link
                  to={`/dashboard/unit/${unit}?page=${meta?.previous_page}`}
                  className="flex items-center font-bold px-4 py-2 text-white bg-indigo-600 rounded-md"
                >
                  Previous
                </Link>
                {meta?.current_page !== 1 && (
                  <Link
                    to={`/dashboard/unit/${unit}?page=${1}`}
                    className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-indigo-600 hover:text-white"
                  >
                    1
                  </Link>
                )}
                {meta?.previous_page !== meta?.current_page &&
                  meta?.previous_page !== 1 && (
                    <Link
                      to={`/dashboard/unit/${unit}?page=${meta?.previous_page}`}
                      className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-indigo-600 hover:text-white"
                    >
                      {meta?.previous_page}
                    </Link>
                  )}

                <span className="px-4 py-2 font-bold rounded-md bg-indigo-600 text-white">
                  {meta?.current_page}
                </span>
                {meta?.next_page !== meta?.current_page &&
                  meta?.next_page !== meta?.total_pages && (
                    <Link
                      to={`/dashboard/unit/${unit}?page=${meta?.next_page}`}
                      className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-indigo-600 hover:text-white"
                    >
                      {meta?.next_page}
                    </Link>
                  )}
                {meta?.current_page + 2 < meta?.total_pages && (
                  <Link
                    to={`/dashboard/unit/${unit}?page=${
                      meta?.current_page + 2
                    }`}
                    className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-indigo-600 hover:text-white"
                  >
                    {meta?.current_page + 2}
                  </Link>
                )}
                {meta?.current_page + 3 <= meta?.total_pages && (
                  <span className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-indigo-600 hover:text-white cursor-pointer">
                    ...
                  </span>
                )}
                {meta?.current_page !== meta?.total_pages && (
                  <Link
                    to={`/dashboard/unit/${unit}?page=${meta?.total_pages}`}
                    className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-indigo-600 hover:text-white"
                  >
                    {meta?.total_pages}
                  </Link>
                )}
                <Link
                  to={`/dashboard/unit/${unit}?page=${meta?.next_page}`}
                  className="px-4 py-2 font-bold text-white bg-indigo-600 rounded-md hover:bg-indigo-600 hover:text-white"
                >
                  Next
                </Link>
              </>
            ) : (
              <ErrorShow msg="Data Kosong" />
            )}
          </div>
        </div>
      )}
    </>
  );
}
