const tokenKey = "simkeuAuthToken";
class TokenStorage {
  static store(token) {
    localStorage.setItem(tokenKey, token);
  }

  static revoke() {
    localStorage.removeItem(tokenKey);
  }

  static getTokenExp() {
    let token = JSON.parse(localStorage.getItem(tokenKey));
    return token.expired_at;
  }

  static getToken() {
    let token = JSON.parse(localStorage.getItem(tokenKey));
    if (token) {
      return token.access_token;
    }
    return "";
  }

  static getRefresh() {
    let token = JSON.parse(localStorage.getItem(tokenKey));
    if (token) {
      return token.refresh_token;
    }
    return "";
  }

  static refreshToken() {
    let token = JSON.parse(localStorage.getItem(tokenKey));
    return token.refresh_token;
  }

  static hasToken() {
    return Boolean(TokenStorage.getToken());
  }
}

export default TokenStorage;
