import { ReactComponent as SuccessIcon } from "../../../../../images/success.svg";

const Success = () => {
    return (
        <>
            <SuccessIcon className="w-32 mb-6" />
            <span className="text-2xl mb-4">Berhasil</span>
        </>
    );
};

export default Success;
