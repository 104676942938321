export default function Popup({
    isOpen,
    toggle,
    title,
    text,
    actionBtn,
    cancelBtn,
    onClose,
}) {
    return (
        <div
            onClick={() => (onClose ? onClose() : null)}
            className={`${
                !isOpen && "hidden"
            } absolute w-screen h-screen inset-0 z-50 flex justify-center items-center bg-gray-600 bg-opacity-75 `}
        >
            <div className="flex flex-col justify-between items-center px-16 py-6 bg-white lg:w-1/3 lg:mr-60 md:w-2/3 w-11/12 h-60 rounded-lg">
                <h4 className="text-3xl">{title}</h4>
                {text && <p className="text-xl text-gray-600">{text}</p>}
                <div className="flex justify-end self-end w-40">
                    {cancelBtn || title === "Konfirmasi" ? (
                        <button
                            onClick={toggle}
                            className="text-red-600 px-4 py-2 border-red-600 border-2 rounded-md hover:bg-red-600 hover:text-white mr-4"
                        >
                            Batal
                        </button>
                    ) : null}
                    <button
                        onClick={onClose}
                        className="bg-indigo-600 px-4 py-2 rounded-md text-white hover:bg-indigo-800"
                    >
                        {actionBtn}
                    </button>
                </div>
            </div>
        </div>
    );
}
