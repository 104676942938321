import { Redirect, Route } from "react-router-dom";
import Auth from "../modules/Auth/auth";

const PublicRoute = ({
  component: Component,
  restricted,
  setModal,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated() && restricted ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} setModal={setModal} />
        )
      }
    />
  );
};

export default PublicRoute;
