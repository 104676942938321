import { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import axios from "../../../lib/axios";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardContent from "../components/DashboardContent";
import Pagu from "./Pagu";
import Users from "./Users";
import Funds from "./Funds";
import Auth from "../../Auth/auth";
import Settings from "./Settings";
import UnitDashboard from "./UnitDashboard";
import Validation from "./Validation";
import { dashboardAccess } from "../../Auth/dashboardAccess";

function Dashboard(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [unitId, setUnitId] = useState(0);
  const [summary, setSummary] = useState({});
  const [spmList, setSpmList] = useState({});
  const [passwordChanged, setPasswordChanged] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dashboardAccess.includes(role)) {
      if (window.sessionStorage.getItem("simakota")) {
        props.setModal("Untad");
        window.sessionStorage.removeItem("simakota");
      }
      setIsLoading(true);
      axios
        .get(`/spm/summary?sort=desc`)
        .then((res) => {
          setSummary(res.data.data);
          setSpmList({
            type: "serapan-desc",
            spms: res.data.data.spms,
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          props.setModal(false);
        });
    }
  }, [role]); // eslint-disable-line

  useEffect(() => {
    axios
      .get("/details")
      .then((res) => {
        setUsername(res.data.data.username);
        setRole(res.data.data.role);
        setUnitId(res.data.data.unit_id);
        setPasswordChanged(res.data.data.status);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleLogout = () => {
    Auth.logout(() => {
      props.history.push("/login");
    });
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        role={role}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          username={username}
          role={role}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          setLogout={handleLogout}
        />

        <main
          style={{
            background: "url(/bg.jpeg) center/contain no-repeat",
            backgroundPositionY: "2rem",
          }}
        >
          <div
            className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto min-h-screen "
            style={{ backgroundColor: "rgba(243, 244, 246, 0.85)" }}
          >
            <Switch>
              <Route
                exact
                path="/dashboard"
                render={() => (
                  <DashboardContent
                    role={role}
                    summary={summary}
                    spmList={spmList}
                    setSpmList={setSpmList}
                    passwordChanged={passwordChanged}
                    setPasswordChanged={setPasswordChanged}
                    isLoading={isLoading}
                  />
                )}
              />
              <Route
                path="/dashboard/unit/:unit"
                render={() => (
                  <UnitDashboard
                    setModal={props.setModal}
                    setModalData={props.setModalData}
                  />
                )}
              />
              <Route
                path="/dashboard/spm"
                exact
                render={() => (
                  <Pagu
                    unidId={unitId}
                    role={role}
                    unitId={unitId}
                    setModal={props.setModal}
                    passwordChanged={passwordChanged}
                    setPasswordChanged={setPasswordChanged}
                  />
                )}
              />
              <Route
                path="/dashboard/validation"
                render={() => (
                  <Validation
                    setModal={props.setModal}
                    role={role}
                    passwordChanged={passwordChanged}
                    setPasswordChanged={setPasswordChanged}
                  />
                )}
              />
              <Route
                path="/dashboard/users"
                render={() => <Users role={role} setModal={props.setModal} />}
              />
              <Route path="/dashboard/funds" component={Funds} />
              <Route
                path="/dashboard/settings"
                render={() => <Settings setModal={props.setModal} />}
              />
            </Switch>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
