import { useCallback, useEffect, useState } from "react";
import axios from "../../../lib/axios";
import PaguForm from "../components/PaguForm";
import ErrorShow from "../components/ErrorShow";
import { paguAccess } from "../../Auth/dashboardAccess";
import { modifiedPaguAccess } from "../../Auth/dashboardAccess";
import { Link } from "react-router-dom";

const Pagu = ({
  role,
  setModal,
  unitId,
  passwordChanged,
  setPasswordChanged,
}) => {
  const [subname, setSubname] = useState("");
  const [status, setStatus] = useState({
    status: 0,
    verify: 0,
  });

  const onSubmitPagu = useCallback(
    (e, data) => {
      e.preventDefault();
      setModal("Loader");

      const formData = new FormData();
      formData.append("file", data);

      axios
        .post(
          role === "admin" ? "/spm/upload" : `spm/${subname}/upload`,
          formData
        )
        .then((res) => {
          if ((res.data.code >= 200) & (res.data.code < 300)) {
            setModal("Success");
          } else {
            setModal(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [setModal, role, subname]
  );

  useEffect(() => {
    if (unitId) {
      axios
        .get(`/units/${unitId}`)
        .then((resp) => {
          setSubname(resp.data.data.sub_name);

          axios
            .get(`/spm/${resp.data.data.sub_name}/status`)
            .then((resp) => {
              setStatus(resp.data.data);
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
  }, [unitId]); // eslint-disable-line

  return (
    <div>
      {paguAccess.includes(role) ? (
        <div>
          {role === "unit" && !passwordChanged && (
            <div className="flex justify-between items-center text-white px-6 py-4 border-0 rounded relative mb-4 bg-yellow-400">
              <span>
                Password belum diubah! Mohon segera ubah password anda.
              </span>
              <span
                className="font-bold cursor-pointer p-1"
                onClick={() => setPasswordChanged(1)}
              >
                X
              </span>
            </div>
          )}
          <div className="border-b-2 flex justify-between">
            <h1 className="text-2xl ">SPM</h1>
            {role === "admin" ? (
              <button
                className="bg-red-600 py-2 px-20 rounded-md text-right text-white"
                onClick={() => setModal("ConfirmDeleteSPM")}
              >
                Hapus SPM
              </button>
            ) : status?.status ? (
              <a
                href={`${process.env.REACT_APP_API_ENDPOINT}/spm/${subname}/download`}
              >
                <button className="bg-indigo-600 py-2 px-4 rounded-md text-right text-white">
                  Download {status.verify === 2 ? "File" : "Template"}
                </button>
              </a>
            ) : (
              <Link to={"/template-spm.xlsx"} target="_blank" download>
                <button className="bg-indigo-600 py-2 px-4 rounded-md text-right text-white">
                  Download {status.verify === 2 ? "File" : "Template"}
                </button>
              </Link>
            )}
          </div>
          {modifiedPaguAccess.includes(role) ? (
            <PaguForm onSubmit={onSubmitPagu} status={status} />
          ) : null}
        </div>
      ) : (
        <ErrorShow msg="Maaf anda tidak memiliki akses" />
      )}
    </div>
  );
};

export default Pagu;
