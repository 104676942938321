import { useRef, useState } from "react";
import axios from "../../../lib/axios";

import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import { ReactComponent as Dropdown } from "../../../images/dropdown.svg";
import { SortString } from "../utils/Sort";

//
const TableUsers = ({ users, setUsers, setSelectedUser, setModal }) => {
  const tableRef = useRef(null);
  const [userData, setUserData] = useState({});
  const [isConfirm, setIsConfirm] = useState(false);

  const getUserDetail = (id) => {
    setModal("Loader");
    axios
      .get(`/users/${id}`)
      .then((resp) => {
        setSelectedUser(resp.data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setModal(false));
  };

  const deleteUser = (id) => {
    setModal("Loader");
    axios
      .delete(`/users/${id}`)
      .then((resp) => setModal("Success"))
      .catch((err) => {
        console.error(err);
        setModal(false);
      });
  };

  return (
    <>
      <div className="overflow-x-auto">
        {/* <div className="w-full lg:w-5/6"> */}
        <div className="bg-white shadow-md rounded my-6">
          <table className="min-w-max w-full table-auto" ref={tableRef}>
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left hover:bg-indigo-300">No.</th>
                <th
                  className="py-3 px-6 text-left hover:bg-indigo-300"
                  onClick={() =>
                    setUsers({
                      type:
                        users.type === "username-desc"
                          ? "username-asc"
                          : "username-desc",
                      data:
                        users.type === "username-desc"
                          ? SortString(users.data, "username", "asc")
                          : SortString(users.data, "username", "desc"),
                    })
                  }
                >
                  <div className="flex items-center">
                    Username
                    <Dropdown
                      className="w-4 ml-2 transition"
                      style={
                        users.type === "username-desc"
                          ? {}
                          : { transform: "rotate(-180deg)" }
                      }
                    />
                  </div>
                </th>
                <th
                  className="py-3 px-6 text-center hover:bg-indigo-300"
                  onClick={() =>
                    setUsers({
                      type:
                        users.type === "role-desc" ? "role-asc" : "role-desc",
                      data:
                        users.type === "role-desc"
                          ? SortString(users.data, "role", "asc")
                          : SortString(users.data, "role", "desc"),
                    })
                  }
                >
                  <div className="flex justify-center items-center">
                    Role
                    <Dropdown
                      className="w-4 ml-2 transition"
                      style={
                        users.type === "role-desc"
                          ? {}
                          : { transform: "rotate(-180deg)" }
                      }
                    />
                  </div>
                </th>
                <th
                  className="py-3 px-6 text-center hover:bg-indigo-300"
                  onClick={() =>
                    setUsers({
                      type:
                        users.type === "unit-desc" ? "unit-asc" : "unit-desc",
                      data:
                        users.type === "unit-desc"
                          ? SortString(users.data, "unit", "asc")
                          : SortString(users.data, "unit", "desc"),
                    })
                  }
                >
                  <div className="flex justify-center items-center">
                    Unit
                    <Dropdown
                      className="w-4 ml-2 transition"
                      style={
                        users.type === "unit-desc"
                          ? {}
                          : { transform: "rotate(-180deg)" }
                      }
                    />
                  </div>
                </th>
                <th className="py-3 px-6 text-center hover:bg-indigo-300">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {/* user data */}
              {users &&
                users.data?.map((user, i) => (
                  // {i+=1}
                  <tr
                    className="border-b border-gray-200 hover:bg-gray-300"
                    key={i}
                  >
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <span className="font-medium">{i + 1}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-left">
                      <span>{user.username}</span>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <span>{user.role}</span>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <span>{user.unit ? user.unit : "-"}</span>
                    </td>

                    {/* action column */}
                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            onClick={() => getUserDetail(user.id)}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            onClick={() => {
                              tableRef.current.scrollIntoView();
                              setUserData(user);
                              setIsConfirm(true);
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* modal */}
      <div
        className={`${
          isConfirm ? "flex" : "hidden"
        } absolute top-0 z-50 left-0 right-0 overflow-hidden w-full h-full justify-center items-center bg-gray-600 bg-opacity-50`}
        style={{ right: "3%", height: "250vh" }}
      >
        <div className="flex flex-col items-center bg-white p-4 w-96 rounded-xl shadow-2xl">
          <CloseIcon
            className="self-end mb-2 w-8 h-8 cursor-pointer"
            onClick={() => {
              setIsConfirm(false);
            }}
          />
          <h4 className="text-2xl mb-4">Hapus User "{userData.username}"?</h4>
          <div className="pb-8 mt-4">
            <button
              className="bg-indigo-600 py-2 px-6 mr-4 rounded-md text-right text-white hover:bg-red-400"
              onClick={() => {
                setIsConfirm(false);
              }}
            >
              Batal
            </button>
            <button
              className="bg-red-600 py-2 px-6 rounded-md text-right text-white hover:bg-indigo-500"
              onClick={() => {
                setIsConfirm(false);
                deleteUser(userData.id);
              }}
            >
              Hapus
            </button>
          </div>
        </div>
      </div>
    </>
    // </div>
  );
};

export default TableUsers;
