import { useState } from "react";
import FileDropzone from "./FileDropzone";

const PaguForm = ({ onSubmit, status }) => {
  const [file, setFile] = useState();

  const renderVerifyStatus = () => {
    switch (status?.verify) {
      case 0:
        return (
          <div className="flex justify-center text-white px-6 py-4 border-0 rounded relative mb-4 bg-yellow-400">
            <span className="inline-block text-center align-middle">
              SPM sedang dalam proses validasi
            </span>
          </div>
        );

      case 2:
        return (
          <div className="flex justify-center text-white px-6 py-2 border-0 rounded relative mb-4 bg-red-500">
            <span className="inline-block text-center align-middle">
              SPM ditolak validator! Silahkan download dan revisi file
            </span>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div>
      <form
        encType="multipart/form-data"
        className="mt-4"
        onSubmit={(event) => onSubmit(event, file)}
      >
        <div className="my-4 flex flex-col item">
          <FileDropzone setFile={setFile} />
        </div>
        <div className="my-4 flex flex-col items-center">
          {!status?.status || status?.verify ? (
            <button
              disabled={!file}
              className={`${
                file ? "bg-indigo-600" : "bg-indigo-400"
              } text-center mb-4 py-2 px-20 rounded-md text-white`}
            >
              {status.verify === 2 ? "Revisi SPM" : "Upload File"}
            </button>
          ) : null}
          {status?.status ? renderVerifyStatus() : null}
        </div>
      </form>
    </div>
  );
};

export default PaguForm;
