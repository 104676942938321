import { formatRupiah } from "../../../utils/Rupiah";

const SPMDetail = ({ data: spm }) => {
  return (
    <div className="flex flex-col px-3 w-full">
      <div className="flex flex-col flex-wrap h-56 border-b-2">
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Tgl. SPM</span>
          <span>{spm?.tanggal_spm || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Tgl. Transfer Bank</span>
          <span>{spm?.tanggal_transfer_bank || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-2 justify-between">
          <span className="text-gray-500 text-sm">Bulan</span>
          <span>{spm?.bulan || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-2 justify-between">
          <span className="text-gray-500 text-sm">Akun</span>
          <span>{spm?.akun || "-"}</span>
        </div>

        <div className="flex flex-col leading-4 mb-2 justify-between">
          <span className="text-gray-500 text-sm">Fakultas</span>
          <span>{spm?.fakultas || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Sifat</span>
          <span>{spm?.sifat || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Sub Komponen</span>
          <span>{spm?.sub_komponen || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Sub Output</span>
          <span>{spm?.sub_output || "-"}</span>
        </div>
      </div>

      <div className="flex flex-col flex-wrap h-56 py-4">
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Kelas Belanja</span>
          <span>{spm?.kelas_belanja || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Komponen</span>
          <span>{spm?.komponen || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">No. SPD 2 D</span>
          <span>{spm?.no_spd_2_d || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">No. SPM</span>
          <span>{spm?.no_spm || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Output</span>
          <span>{spm?.output || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Pengesahan</span>
          <span>{spm?.pengesahan || "-"}</span>
        </div>
        <div className="flex flex-col leading-4 mb-3 justify-between">
          <span className="text-gray-500 text-sm">Jumlah</span>
          <span>{spm.jumlah ? formatRupiah(spm?.jumlah) : 0}</span>
        </div>
      </div>
    </div>
  );
};

export default SPMDetail;
