export const userRoles = ["admin", "supervisor", "validator", "unit"];

// dashboard
export const dashboardAccess = ["admin", "supervisor"];

// pagu
export const paguAccess = ["admin", "unit"];
export const modifiedPaguAccess = ["admin", "unit"];

// users
export const usersAccess = ["admin"];

// validator
export const validationAccess = ["admin", "validator"];
