import axios from "../../../../../lib/axios";

const ConfirmDeleteSPM = ({ setModal }) => {
  const confirm = () => {
    setModal("Loader");
    axios
      .delete("/spm")
      .then((res) => {
        if ((res.data.code >= 200) & (res.data.code < 300)) {
          setModal("Success");
        } else {
          setModal(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h4 className="text-2xl mb-4">Hapus SPM ?</h4>
      <div className="pb-8 mt-4">
        <button
          className="bg-indigo-500 py-2 px-6 mr-4 rounded-md text-right text-white hover:bg-indigo-400"
          onClick={() => setModal(false)}
        >
          Batal
        </button>
        <button
          className="bg-red-600 py-2 px-6 rounded-md text-right text-white hover:bg-red-500"
          onClick={() => confirm()}
        >
          Hapus
        </button>
      </div>
    </>
  );
};

export default ConfirmDeleteSPM;
