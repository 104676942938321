export const SortNumber = (arr, key, type) => {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr.length - i - 1; j++) {
      if (arr[j][key] > arr[j + 1][key]) {
        [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
      }
    }
  }
  if (type === "desc") return arr.reverse();
  return arr;
};

export const SortString = (arr, key, type) => {
  arr.sort((a, b) => {
    if (String(a[key]) < String(b[key])) {
      return -1;
    }
    if (String(a[key]) > String(b[key])) {
      return 1;
    }
    return 0;
  });

  if (type === "desc") return arr.reverse();
  return arr;
};
